import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/system-CRM-blog.png";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: { eq: "prosty-crm-ikony-w-szansach-300x184.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: { eq: "prosty-crm-Raporty-lejek-szans-sprzedazy.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(relativePath: { eq: "prosty-crm-przyczyny-utraty.png" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "prosty-crm-Zarządzanie-uprawnieniami.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const SystemCRM = ({ data }) => {
  return (
    <Artykul
      title="Proces sprzedaży projektowej w systemie CRM"
      articleImage={obrazekArtykulu}
      keywords={["proces sprzedaży projektowej."]}
      articleImageAlt="Proces sprzedaży projektowej w systemie CRM - QuckCRM"
      metaTitle="Proces sprzedaży projektowej w systemie CRM"
      metaDescription="✅ System CRM • Najważniejszym celem każdego produktu jest jak najlepsze zaspokojenie potrzeb klienta. Proces sprzedaży projektowej w systemie CRM - QuickCRM"
    >
      <h2>Jak skutecznie pozyskiwać klientów?</h2>
      <br />
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="Proces sprzedaży projektowej w systemie CRM"
        alt="Proces sprzedaży projektowej w systemie CRM"
        fluid={data.zdjecie1.childImageSharp.fluid}
      />
      <p>
        <br />
        Dzięki systemowi CRM możemy zobaczyć cały proces sprzedaży w jednym
        miejscu. Jest to korzystne dla handlowca, który może od razu wskazać
        takie szanse, które powinny uzyskać wyższy priorytet. W ten sposób może
        zaplanować sobie zadania zgodnie z określonym pierwszeństwem. Może to
        określić na podstawie etapu szansy sprzedaży i zadań przypisanych do
        niej. Na każdym etapie sprzedaży definiuje się cele, które musi spełnić
        pracownik, aby szansa przeszła do następnego etapu. Zadania dopisane do
        konkretnej szansy są niezwykle ważne, bo dzięki nim handlowcy nie
        zapominają skontaktować się z klientem, zbadać jego potrzeb lub
        przedstawić ofertę. Podgląd szans sprzedaży sprawia, że mamy nad nimi
        kontrolę, nie pozostawiamy ich samymi sobie. W systemie CRM możemy
        połączyć z szansą wiele przydatnych informacji, takich jak historia
        korespondencji z klientem, zaplanowane zadania czy szczegóły ustaleń
        wewnętrznych dotyczących danego klienta. Dzięki temu możemy zapewnić
        ciągłość obsługi. W przypadku nieobecności jednego pracownika, inni mogą
        od razu podjąć inicjatywę i przejąć szansę, ponieważ mają dostęp do
        wszystkich potrzebnych informacji.
      </p>
      <h2>Korzyści dla kierownika, czyli widok szans i raporty</h2>

      <p>
        Przełożony może na podstawie widoku szans sprzedaży określić stopień
        osiągnięcia założonych celów i koordynować procesem sprzedaży w skali
        makro. Widok szans sprzedaży jest dla kierownika źródłem cennej wiedzy,
        takiej jak np. stosunek liczby powstających szans do liczby
        zrealizowanych transakcji. Jeśli będzie on na bieżąco śledził postępy
        swojego zespołu, będzie mógł natychmiast reagować na powstające
        problemy, takie jak zastój na określonym etapie. W ten sposób będzie
        wiedział, jakie działania optymalizacyjne wprowadzić. Jednak największym
        plusem systemu CRM dla kierownika jest bogaty wachlarz raportów, które
        system tworzy automatycznie na podstawie wszystkich danych wprowadzonych
        przez pracowników. Dzięki automatyzacji tworzenia tych raportów
        kierownik oszczędza czas na samodzielne przygotowywanie zestawień i może
        od razu zabrać się do analizy danych.
      </p>
      <h2>Lejek szans sprzedaży</h2>
      <p>
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Proces sprzedaży projektowej w systemie CRM"
          alt="Proces sprzedaży projektowej w systemie CRM"
          fluid={data.zdjecie2.childImageSharp.fluid}
        />
        Prosty CRM - lejek szans sprzedaży
        <br />
        <br />
        Taki raport przedstawia ogólną wartość szans sprzedaży na każdym z
        etapów. Dzięki przedstawieniu tych danych w jednym miejscu możliwe jest
        analizowanie zaplanowanych celów i ewentualna zmiana strategii. Widząc,
        że planowana wartość sprzedaży nie zaspokoi potrzeb przedsiębiorstwa,
        kierownik może podjąć kluczowe decyzje zanim nastąpią daleko idące
        konsekwencje. To jest właśnie główna zaleta systemu CRM - umożliwia on
        bieżącą obserwację wszystkich procesów zachodzących w firmie. Przydatnym
        raportem jest także konwersja lejka – informacja o tym, ile szans
        przechodzi do kolejnych etapów oraz jaka ich część została utracona, a
        jaka wygrana na poszczególnych etapach. Jeśli widzimy, że na danym
        etapie tracimy najwięcej szans sprzedaży, wiemy, że należy wesprzeć
        właśnie ten etap. W zależności od etapu usprawnienia będą miały różny
        charakter, na przykład przez poprawienie przygotowywania ofert lub
        udoskonalenie pierwszego kontaktu z klientem.
      </p>
      <h2>To, co możemy zmierzyć – możemy też zmienić</h2>
      <p>
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Proces sprzedaży projektowej w systemie CRM"
          alt="Proces sprzedaży projektowej w systemie CRM"
          fluid={data.zdjecie3.childImageSharp.fluid}
        />
        <br />
        Wychodząc z tego założenia rozjaśnia się przed nami główny powód
        korzystania z systemu CRM. Jeśli będziemy w stanie obiektywnie ocenić
        każdy etap sprzedaży, każdą decyzję, którą podjęliśmy, wtedy możemy
        podjąć odpowiednie kroki i zwiększyć efektywność naszych handlowców.
        Jednak aby to zrobić, należy wcześniej umożliwić kompleksowe zbadanie
        całego procesu sprzedażowego. Dużo możliwości daje nam raport ukazujący
        przyczyny utraty szans. Dzięki niemu możemy zbadać jaka przyczyna stoi
        za większością utraconych szans i podjąć odpowiednie kroki w celu
        poprawy tego stanu. Mamy także możliwość analizy konkretnych szans w
        postaci raportu utracone szanse. Raport ten wyjaśnia, z jakiego powodu
        dane szanse zostały utracone. Każdorazowo pracownik dołącza do takiej
        szansy komentarz, wyjaśniający konkretne przyczyny, a dostęp do tych
        informacji pozwoli na przygotowywanie jeszcze lepszych ofert i
        skuteczniejszą sprzedaż.
      </p>
      <h2>Znaleźć wszystkie słabe punkty</h2>
      <p>
        Korzystając z opisanych raportów jesteśmy w stanie wskazać wszystkie
        miejsca, w których powstają trudności w procesie sprzedaży. Dzięki
        świadomości istnienia i postaci problemów mamy możliwość opracowania
        odpowiedniej strategii wyjścia z kryzysu i powrotu na właściwy tor.
        Świadomość problemu jest niezwykle ważna, ponieważ człowiek z natury
        przyzwyczaja się do pewnych stanów, nawet jeśli są one niekorzystne.
        System CRM umożliwia zlokalizowanie wszystkich słabych punktów, dzięki
        czemu możemy podjąć obiektywną analizę i zaplanować najlepszą strategię.
      </p>
      <h2>Docenić pracowitych</h2>
      <p>
        Niezmiernie ważne jest, aby doceniać pracowników, którzy wykazują się
        dużą skutecznością. Korzystając z informacji dostarczanych przez system
        CRM, możesz wskazać tych handlowców, którzy wykazują się determinacją i
        zaangażowaniem, w konsekwencji odnosząc sukcesy. Jeśli będziesz o nich
        dbać i ich doceniać, masz pewność, że w przyszłości będą osiągali
        jeszcze lepsze rezultaty. Możesz dzięki temu zapewnić swoim klientom
        najlepszą obsługę. Twoi klienci na pewno docenią fakt, że są traktowani
        wyjątkowo.
      </p>
      <h2>Rozwiązywanie problemów na bieżąco</h2>
      <p>
        Czasem zdarza się, że nasze szanse zostają zablokowane na jednym z
        etapów, a ich przejście do kolejnego jest powolne i trudne. Wtedy należy
        szybko zdiagnozować problem. Może czas na wprowadzenie nowego produktu
        do oferty? Rozszerzenie działu sprzedaży o nowe osoby? Modyfikacja
        sposobu przedstawienia oferty? Warto nie dopuszczać do pojawienia się
        problemów, a zapobiegać ich występowaniu. Właśnie tutaj swoje
        zastosowanie znajduje system CRM, które pozwalają nam spojrzeć nowym
        wzrokiem na niektóre sprawy. Zgodnie z uniwersalną zasadą lepiej
        zapobiegać niż leczyć, nie powinniśmy doprowadzać do zaistnienia takich
        sytuacji, a starać się na bieżąco im zapobiegać, dbając o ciągłe
        udoskonalanie procesu sprzedaży.nnowacyjne rozwiązania w postaci
        systemów CRM dają pełen wachlarz możliwości rozwoju Twojej firmy, nowych
        sposobów na sprawianie radości Twoim klientom i pracownikom. Wkład
        Excela w rozwój nowoczesnego biznesu jest oczywiście ogromny i nie
        należy go lekceważyć. To wciąż genialne rozwiązanie dla wielu osób i
        organizacji, jednak nie daje on tylu szans i możliwości co systemy CRM.
        Dlatego jeśli chcesz rozwijać swój biznes powinieneś rozważyć zmianę
        Excela na dobry i sprawdzony CRM.
      </p>
    </Artykul>
  );
};

export default SystemCRM;
